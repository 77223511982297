html {
  background-color: rgb(251, 250, 248);
}
body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(251, 250, 248);
}

code {
  font-family: "Poppins";
}
